@import "assets/scss/variables/box-shadow.scss";


.cdk-overlay-pane {

  .mat-dialog-container {
    border-radius: var(--lms-brand-radius-l);
  }

  &.mobile-full-width-dialog {
    @media (max-width: $desktop) {
      width: auto;
      min-width: 100vw !important;
  
      .mat-dialog-container { 
        border-radius: var(--lms-brand-radius-l) var(--lms-brand-radius-l) 0 0 ;
        min-height: calc(100vh - #{rem-calc(20)});
        min-height: calc(100dvh - #{rem-calc(20)});
      }

      &.dialog-no-margin {
        .mat-dialog-container { 
          min-height: 100vh;
          min-height: 100dvh;
          border-radius: 0;
        }
      }
    }
  }

  &.dialog-no-margin {
    .mat-dialog-container {
      margin-top: 0;
    }
  }
}

.course-details-dialog {
  width: clamp(40vw, #{rem-calc(870)}, 90vw);
}

.dialog-no-padding {
  .mat-dialog-container {
    padding: 0;

    @media (min-width: $desktop) {
      min-width: rem-calc(600);
    }
  }
}

.chat-record-media-dialog {
  &.video_message {
    height: 100%;

    @media (min-width: $desktop) {
      height: auto;
    }
  }

  .mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
  }
}

.dialog-light-box-shadow {
  .mat-dialog-container {
    box-shadow: $box-shadow-evaluation-L-2;
  }
}

.dialog-base-radius {
  .mat-dialog-container {
    border-radius: var(--lms-brand-radius-l);
  }
}

.forum-dialog {
  $self: &;

  .mat-dialog-container {
    width: rem-calc(760);
    max-width: 90vw;
    padding: rem-calc(16);

    @media (min-width: $breakpoint-medium) {
      padding: rem-calc(32 32 24);
    }

    #{$self}__main {
      overflow-x: hidden;
    }

    #{$self}__title {
      font-size: rem-calc(26);
      margin-bottom: rem-calc(8);

      @media (min-width: $breakpoint-medium) {
        margin-bottom: rem-calc(16);
      }
    }

    #{$self}__subtitle {
      font-size: rem-calc(16);
      margin-top: rem-calc(8);
    }

    #{$self}__form {
      margin: rem-calc(8 0 24);

      @media (min-width: $breakpoint-medium) {
        margin: rem-calc(16 0 32);
      }

      .ng-select {
        margin: 0;
        width: 100%;
      }
    }

    #{$self}__actions {
      margin: rem-calc(16 0 0);
      padding: 0;
    }

    #{$self}__info {
      color: $blueDark;
      background-color: $blueLight;
      padding: rem-calc(6);
      border-radius: var(--lms-brand-radius-s);
      text-align: center;

      font: {
        size: rem-calc(12);
        weight: $font-weight-bold;
      }

      @media (min-width: $breakpoint-medium) {
        padding: rem-calc(10 12);
        font-size: rem-calc(14);
      }
    }

    .mat-dialog-content {
      @media (max-width: $breakpoint-medium) {
        max-height: none;
      }
    }


    #{$self}__button {
      padding: rem-calc(8 16);
      height: rem-calc(50);

      &.mat-button-disabled {
        pointer-events: none;
        opacity: .5;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: rem-calc(16);
        padding: rem-calc(8 40);
      }

      &.primary {
        background-color: var(--lms-primary-color);;
        color: $white;
        min-width: rem-calc(160);

        @media (min-width: $breakpoint-medium) {
          min-width: rem-calc(200);
        }
      }
    }
  }
}
