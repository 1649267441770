@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
}


@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  src: url("../../assets/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
}
