@import 'variables/breakpoint.scss';
@import 'variables/colors.scss';
@import './layout.scss';


.mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-outline-start {
    border-radius: var(--lms-brand-radius-s) 0 0 var(--lms-brand-radius-s);
  }

  .mat-form-field-outline-end {
    border-radius: 0 var(--lms-brand-radius-s) var(--lms-brand-radius-s) 0;
  }

  &.gray-form-field {
      .mat-form-field-outline {
        background-color: $lightGrey5;
        border-radius: var(--lms-brand-radius-xs);
        overflow: hidden;
  
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border-color: $grayBorder;
        }
      }
    }

  &.datepicker {
    .mat-form-field-wrapper {
      .mat-form-field-suffix {
        top: rem-calc(2);
        left: rem-calc(18);

        
        .mat-datepicker-toggle {     
          .mat-button-base {
            height: rem-calc(60);
            width: rem-calc(60);

            .mat-button-wrapper {
              display: flex;
              height: 100%;
              .mat-icon {
                line-height: rem-calc(20);
              }
            }
          }
        }
      }
    }
  }


  &.ng-invalid.ng-touched {
    .mat-form-field-outline {
      .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
        border-color: $red2;
      }
    }

    .mat-form-field-infix {
      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          color: $red2;
        }
      }
    }
  }

  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-outline {
      .mat-form-field-outline-gap {
        border-top-color: transparent;
      }
    }
  }

  &.form-field-textarea {
    .mat-form-field-wrapper {
      min-height: rem-calc(52);
      height: auto;
      padding-bottom: 0;
  
      .mat-form-field-outline {
        min-height: rem-calc(52);
        height: auto;
      }
  
      .mat-form-field-flex {
        padding-bottom: rem-calc(8);
        align-items: flex-start;
      }
    
      .mat-form-field-infix {
        min-height: rem-calc(52);
        height: auto;
      }
    }
  }

  &.form-field-chat {
    .mat-form-field-wrapper {       
      .mat-form-field-infix {
        padding: rem-calc(10 0 12 4);
      }

      .mat-form-field-prefix {
        margin-top: rem-calc(8);
      }

      .mat-form-field-flex {
        padding: rem-calc(0 8 8);
      }
    

      .mat-form-field-suffix {
        margin-top: rem-calc(8);
      }
    
      .mat-form-field-suffix, .mat-form-field-prefix { 
        top: rem-calc(4);
        .mat-icon-button.mat-button-base {
          height: rem-calc(40);
          width: rem-calc(40);
  
          .mat-icon {
            width: rem-calc(20);
            height: rem-calc(20);
  
            svg {
              stroke: $gray100;
            }
          }
        }
      }
    }
  }

  &.form-field-search {
    mat-icon {
      width: rem-calc(20);
      height: rem-calc(20);
      font-size: rem-calc(20);
      color: $gray100;
    }

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding-left: rem-calc(16);
      }
      .mat-form-field-infix {
        padding-left: rem-calc(16);
      }

      .mat-form-field-prefix {
        top: 0;
      }
    }
  }

  &.form-field-small {
    height: rem-calc(44);
  
    .mat-form-field-wrapper {
      height: rem-calc(44);

      .mat-form-field-flex {
        padding: rem-calc(0 8);
      }
  
      .mat-form-field-outline {
        height: rem-calc(44);
      }
  
      .mat-form-field-infix {
        height: rem-calc(44);
        padding: rem-calc(8 0 8 0);
      }
  
      .mat-form-field-suffix, .mat-form-field-prefix {
        top: 0;
      }
    }
  }

  &.password-field {
    .mat-form-field-wrapper {
      .mat-form-field-suffix {
        top: 50%;
        right: 12px;
        position: absolute;
        transform: translateY(#{rem-calc(-10)});
        background: $white;
      } 
    }
  }

  .mat-form-field-wrapper {
    height: rem-calc(60);

    .mat-form-field-flex {
      margin-top: 0;
    }
  
    .mat-form-field-flex {
      padding: rem-calc(0 20);
    }

    .mat-form-field-outline {
      height: rem-calc(60);

      .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
        border-color: $grayBorder50;
      }
    }

    .mat-form-field-infix {
      height: rem-calc(60);
      padding: rem-calc(12 0);

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          color: $gray100;
          font-weight: $font-weight-semibold;
        }
      }
    }

    .mat-form-field-suffix, .mat-form-field-prefix {
      top: rem-calc(2);

      .mat-icon-button.mat-button-base {
        height: rem-calc(24);
        width: rem-calc(24);

        .mat-icon {
          width: rem-calc(22);
          height: rem-calc(22);

          svg {
            stroke: $gray100;
          }
        }
      }
    }
  }
}

.search-input {
  position: relative;

  .input {
    padding: rem-calc(16 30 16 52);
    height: rem-calc(56);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: rem-calc(16);
    transform: translateY(-50%);
    z-index: 1;

    &.mat-icon {
      width: rem-calc(16);
      height: rem-calc(16);
      font-size: rem-calc(16);
      line-height: rem-calc(16);
      color: $gray65;
    }

    &.clear-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(40);
      height: 100%;
      left: auto;
      right: 0;
      cursor: pointer;

      &:hover {
        color: $black;
      }
    }
  }
}


.input {
  display: flex;
  border: rem-calc(1) solid $content-basic-content-lower;
  border-radius: var(--lms-brand-radius-s);
  background: $white;
  flex-direction: row;
  align-items: center;
  padding: rem-calc(12 24 12 16);
  isolation: isolate;
  color: $black;
  height: rem-calc(44);
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font: {
    size: rem-calc(16);
    weight: $font-weight-regular;
  }

  &--rounded {
    border-radius: var(--lms-brand-radius-horizontal, rem-calc(42));
  }

  &::placeholder {
    color: $gray100;
  }

  &.ng-invalid.ng-dirty, &.ng-invalid.ng-touched {
    border-color: $surface-red;

    &::placeholder {
      color: $surface-red;
    }
  }


  &--placeholder-grey {
    &::placeholder {
      color: $content-basic-content-low;
    }
  }

  &:focus,
  &:active {
    border-color: $black;
  }

  &-textarea {
    height: auto;
  }

  &-group {
    label {
      display: inline-block;
      color: $content-basic-content-high;
      line-height: rem-calc(18);
      margin-bottom: $spacing-2;
      letter-spacing: .02;
      font: {
        size: rem-calc(14);
        weight: $font-weight-medium;
      }
    }
  }
}

.textarea {
  @extend .input;
  min-height: rem-calc(120);
  max-width: 100%;
  resize: none;
  border-radius: var(--lms-brand-radius-m);
}
